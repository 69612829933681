.img-container {
    height: 92px;
    padding: 0 48px;
    display: flex;
    align-items: center;
    gap: 26px;
}

.img-container hr {
    background: var(--primary);
    height: 43px;
    border-radius: 1px;
}

.bottom-line {
    height: 14px;
    display: flex;
}

.bottom-line div:nth-child(1) {
    width: calc(100% / 3);
    height: 100%;
    background-color: var(--alternative2);
}

.bottom-line div:nth-child(2) {
    width: calc(100% / 3);
    height: 100%;
    background-color: var(--alternative1);
}

.bottom-line div:nth-child(3) {
    width: calc(100% / 3);
    height: 100%;
    background-color: var(--primary);
}
