.custom-result {
    text-align: center;
    height: inherit;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: center;
}

.custom-result h2 {
    margin: 5px 0;
}

.custom-result p {
    width: 20%;
    margin: 0 auto;
    font-size: 16px;
}
