:root {
    --primary: #004b81;
    --alternative1: #971b2f;
    --alternative2: #e1a113;
    --black: #000;
    --white: #fff;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
    height: 100vh;
}

footer {
    height: 106px;
}

.flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.appbar {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    min-height: 60px;
    max-height: 60px;
}

.bg-container {
    background-color: #6565650d;
    height: 100%;
}

.main-wrapper {
    height: calc(100% - 106px);
    display: block;
}

.MuiDataGrid-root {
    border: none !important;
}

.MuiDataGrid-main {
    margin-top: 1em;
}

.MuiTextField-root {
    margin: 10px 0 !important;
}

.MuiTextField-root .MuiFormLabel-asterisk {
    color: red;
}

.input-textfield label,
.input-textfield p {
    color: #476773;
    margin-left: 0px;
}

.input-textfield .MuiInputAdornment-root:hover {
    cursor: pointer;
}

.MuiFormControl-root input,
.MuiInputBase-root {
    background: var(--white);
}

#notistack-snackbar svg {
    margin-right: 5px;
    font-size: 20px;
}

.MuiTab-root.Mui-selected {
    color: var(--primary) !important;
}

.section-title {
    color: #656565;
    margin-top: 16px;
    margin-bottom: 24px;
}

.MuiPopper-root > .MuiTooltip-tooltip {
    background: var(--white);
    font-weight: 600;
    font-size: 13px;
    border: 1px solid var(--primary);
    border-radius: 10px;
    color: var(--primary);
    padding: 8px;
}

.MuiFormLabel-root.Mui-focused {
    color: #00000099 !important;
}

.MuiFormLabel-root.Mui-focused b {
    color: #00000099;
    font-weight: 600;
}

.close-button-container {
    display: flex;
    justify-content: right;
    height: 20px;
    margin: 15px;
}

.drawer-container {
    width: 606px;
    height: inherit;
}

.drawer-body {
    min-height: calc(100% - 215px);
    padding: 24px 32px;
}

.drawer-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 24px 32px;
}

.close-button-container svg {
    font-size: 25px;
}

::-webkit-scrollbar {
    width: 7px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    background: #d4dade;
    border-radius: 15px;
}

@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) -sm/md-*/
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 900px and 1199px) -md- */
@media only screen and (min-width: 900px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) -lg-*/
@media only screen and (min-width: 1200px) {
}

/* @media only screen and (max-height: 480px) {
    .main-wrapper {
        height: auto;
    }
} */
