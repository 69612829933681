.alert-container svg {
    font-size: 120px;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
}

.dialogstyle div h2 {
    flex-direction: column;
    justify-content: center;
    font-size: 26px;
    line-height: 1.2;
}

.dialogstyle div:nth-child(2) {
    width: auto;
    height: auto;
    display: flex;
}

.dialogstyle div p {
    font-size: 18px;
    font-weight: 500;
    color: var(--black);
}

.alert-container h2 {
    overflow-y: auto;
    max-height: 250px;
}

.alert-container div img {
    height: 150px;
}
